import ReactEcharts from 'echarts-for-react';
import React from 'react';

const PriceChart = ({ asset }) => {
  const prices = asset['closes']
  // const sma_slow = asset['4_hour_200_sma']
  // const sma_fast = asset['4_hour_60_sma']
  // const std360 = asset['4_hour_360_std']
  // const sma360 = asset['4_hour_360_sma']

  // Define the lane boundaries and corresponding colors
  // const lanes = ["start", -3, -2.8, -2.6, -2.4, 2.4, 2.6, 2.8, 3];
  // const colors = {
  //   '-3': 'transparent',
  //   '-2.8': 'rgba(0, 255, 0, 0.2)',
  //   '-2.6': 'rgba(0, 255, 0, 0.1)',
  //   '-2.4': 'rgba(0, 255, 0, 0.05)',
  //   '2.4': 'transparent',
  //   '2.6': 'rgba(255, 0, 0, 0.05)',
  //   '2.8': 'rgba(255, 0, 0, 0.1)',
  //   '3': 'rgba(255, 0, 0, 0.2)',
  // };
  // Calculate lane data
  // const laneSeries = lanes.slice(0, -1).map((start, i) => {
  //   if (start === "start") {
  //     return {
  //       type: 'line',
  //       stack: 'lanes',
  //       smooth: true,
  //       showSymbol: false,
  //       lineStyle: { width: 0 }, // Hide the line
  //       areaStyle: {
  //         color: colors[-3],
  //       },
  //       data: sma360.map((sma, idx) => sma !== null ? (sma - (3 * std360[idx])) : null
  //       ),
  //     }
  //   }
  //   const end = lanes[i + 1];

  //   // For each lane, if the z-score falls between 'start' and 'end' (inclusive),
  //   // color the area with the corresponding color
  //   return {
  //     type: 'line',
  //     stack: 'lanes',
  //     smooth: true,
  //     showSymbol: false,
  //     lineStyle: { width: 0 }, // Hide the line
  //     areaStyle: {
  //       color: colors[end.toString()],
  //     },
  //     data: sma360.map((sma, idx) => sma !== null ? (sma + (end * std360[idx])) - (sma + (start * std360[idx])) : null
  //     ),
  //   };
  // });


  const getOption = () => {
    return {
      xAxis: {
        type: 'category',
        data: prices.map((_, index) => index),  // Use index as the x-axis labels
        show: false  // Hide x-axis
      },
      yAxis: {
        type: 'value',
        show: false,  // Hide y-axis,
        min: 'dataMin',  // Auto-fit to the minimum value in data
        max: 'dataMax',  // Auto-fit to the maximum value in data
      },
      series: [
        // ...laneSeries,
        {
          data: prices,
          type: 'line',
          lineStyle: {
            color: '#ffffff',  // White line
            width: 2
          },
          symbol: 'none',  // Remove the marker symbols (circles)
          showSymbol: false,  // Also hide any symbols if they were set elsewhere
        },
        // {
        //   data: sma_slow,
        //   type: 'line',
        //   lineStyle: {
        //     color: '#eee',  // White line
        //     width: 2
        //   },
        //   symbol: 'none',  // Remove the marker symbols (circles)
        //   showSymbol: false,  // Also hide any symbols if they were set elsewhere
        // },
        // {
        //   data: sma_fast,
        //   type: 'line',
        //   lineStyle: {
        //     color: '#eee',  // White line
        //     width: 1
        //   },
        //   symbol: 'none',  // Remove the marker symbols (circles)
        //   showSymbol: false,  // Also hide any symbols if they were set elsewhere
        // },

      ],
      tooltip: {
        show: false  // Disable tooltip
      },
      legend: {
        show: false  // Hide legend
      },
      grid: {
        left: 0,  // Adjust grid to remove any margins
        right: 0,
        top: 0,
        bottom: 0
      }
    };
  };

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: '520px', width: '100%' }}  // Set height and width as needed
    />
  );
};

export default PriceChart;
